import SignIn from "../sign-in/sign-in";
import pianoService from "../../services/piano/piano-service";
import triggerSignInRegisterModal from "../../react/SubscriptionOffers/SignInRegistrationModal.jsx";

var hasContract = window.location.search.indexOf("contractId") > 0;
if(hasContract) {
	var tpObject = window["tp"] || [];
	window.isSiteLicensing = true;
	tpObject.push(["addHandler", "loginRequired", function(params) {
		let container = document.body.appendChild(document.createElement("div"));
		triggerSignInRegisterModal(container, "registration", "HBR Registration", "", window);

		tpObject.checkoutParams = params;
	}]);

	SignIn.signInPromise
		.then(function() {
			pianoService.getUserRef()
				.then((pianoUser) => {
					tpObject.push(["setUserRef", pianoUser]);

					tpObject.push(["init", function() {
						if (tpObject.user.isUserValid()) {
							tpObject.offer.startCheckout(tpObject.checkoutParams)

							pianoService.completeSubscriptionActivation().then(function(subscriber) {
								console.log(subscriber);
							});

						}
					}]);
				});
		});
}
